/* LandingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.landing-page {
  font-family: 'StyreneA',sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #e6f3ff; /* Add background color to the entire page container */
  
}

.landing-page .logo-image {
  width: 80px;
  height: 80px;
}

.landing-page .section-header {
  padding: 0% 10% 2%;
  background-color: #ffffff; /* Ensure the background color is consistent throughout the section */
  box-sizing: border-box;
  background-color: #ffffff;
}

.landing-page .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: fit-content;
  padding: 12px 200px;
  background-color: #ffffff; /* Add a white background to the header */
}

.landing-page .logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  width: fit-content;
  height: fit-content;
  gap: 15px;
}

.landing-page .nav {
  display: flex;
  align-items: center;
  gap: 25px;
}

.landing-page .nav-item {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
}

.landing-page .sign-up-button {
  background-color: #0066ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 700;
  font-size: 15px;
}

.landing-page .main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  padding: 40px 0;
}

.landing-page .left-section {
  width: 50%;
}

.landing-page .right-section { 
  width: 40%;
}

.landing-page .tagline {
  color: #0066ff;
  font-weight: bold;
  margin-bottom: 20px;
}

.landing-page .main-heading {
  font-size: 56px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 800;
  font-size: 3.3rem;
  letter-spacing: -0.0625rem;
}

.landing-page .description {
  color: #000000;
  margin-bottom: 30px;
  font-size: 18px;
}

.landing-page .cta-button {
  background-color: #0066ff;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}

.landing-page .features-link {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
  font-weight: 600;
}

.landing-page .image {
  max-width: 100%;
  
}

.landing-page .feature-selection {
  padding: 0 8%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.landing-page .feature-selections-wrapper {
  background-color: #ffffff;
  height: 853px;
  padding-bottom: 100px;
}

.landing-page .feature-selection .section-header {
  text-align: center;
  padding: 0% 0% 2% 0%;
  background-color: #ffffff;
}

.landing-page .feature-selection .section-header h2 {
  font-size: 2em;
  margin: 10px 0;
  color: #333;
}

.landing-page .feature-selection .section-header p {
  max-width: 800px;
  margin: 0 auto;
  color: #666;
  font-size: 1.1em;
  line-height: 1.6;
}

.landing-page .feature-heading {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.landing-page .feature-container {
  display: flex;
  justify-content: center;
  align-items: top;
  gap: 25px;
}

.landing-page .feature-list {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  gap: 15px;
}

.landing-page .feature-item:hover, .landing-page .feature-item.selected {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  opacity: 100%;
}

.landing-page .benefit-item:hover, .landing-page .benefit-item.selected {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  opacity: 100%;
}

.landing-page .feature-text h3 {
  margin: 0 0 5px 0;
}

.landing-page .feature-text p {
  margin: 0;
  font-size: 15px;
  color: #666;
  text-align: left;
}

.landing-page .product-display {
  width: 45%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: none; /* Hide by default */
}

.landing-page .product-display.visible {
  display: block; /* Show when visible class is added */
}

.landing-page .product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.landing-page .sign-in-button {
  background-color: #0066ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.landing-page .product-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 20px;
}

.landing-page .pricing-section {
  padding: 60px 10%;
  background-color: #f8f9fa;
}

.landing-page .pricing-heading {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 40px;
}

.landing-page .pricing-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.landing-page .pricing-card {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.landing-page .pricing-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.landing-page .pricing-card p {
  color: #666;
  margin-bottom: 20px;
}

.landing-page .price {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.landing-page .price span {
  font-size: 0.5em;
  font-weight: normal;
}

.landing-page .features-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  flex-grow: 1;
}

.landing-page .features-list li {
  margin-bottom: 10px;
}

.landing-page .get-started-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.landing-page .get-started-button:hover {
  background-color: #1557b0;
}

.landing-page .features-grid-section {
  padding: 0px 8%;
  background-color: #e6f3ff;
  padding-bottom: 100px;
}

.landing-page .features-columns {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.landing-page .feature-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.landing-page .column-header {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

.landing-page .feature-item {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.landing-page .benefit-item {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  opacity: 100%;
  margin: 0 auto;
  height: 150px;
}

.landing-page .benefit-header {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  height: auto;
  margin-bottom: 10px;
}

.landing-page .feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.landing-page .benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.landing-page .feature-icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.landing-page .benefit-icon {
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  scale: 0.8;
  width: 35px;
  height: 35px;
}

.landing-page .feature-item h3 {
  font-size: 1em;
  margin-bottom: 8px;
}

.landing-page .feature-item p {
  font-size: 0.9em;
  color: #666;
  text-align: left;
}

.landing-page .benefit-item h3 {
  font-size: 1.1em;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.landing-page .benefit-item p {
  font-size: 1em;
  color: #666;
  text-align: left;
  font-weight: 500;
  margin: 0;
}

.landing-page .features-section {
  padding: 60px 0; /* Remove horizontal padding */
  background-color: #ffffff;
  overflow: hidden;
  width: 100vw;
  max-width: 100%; /* Ensure it doesn't exceed viewport width */
  margin-left: calc(-50vw + 50%); /* Center the section */
  margin-right: calc(-50vw + 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page .features-heading {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  padding: 0 20px; /* Add some padding to the heading */
}

.landing-page .feature-cards-container {
  display: flex;
  gap: 20px;
  padding: 0 20px 20px; /* Add horizontal padding */
  cursor: grab;
  user-select: none;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  max-width: 100vw;
}

.landing-page .feature-cards-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.landing-page .feature-cards-container:active {
  cursor: grabbing;
}

.landing-page .feature-card {
  flex: 0 0 auto;
  width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.landing-page .feature-image {
  height: 150px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
}

.landing-page .feature-card h3 {
  font-size: 1em;
  margin: 15px;
  color: #333;
}

.landing-page .feature-card p {
  font-size: 0.8em;
  margin: 15px;
  color: #666;
}

@media (max-width: 768px) {
  .landing-page .feature-card {
    width: 250px;
  }
  
  .landing-page .feature-card {
    width: 250px;
  }
}

@media (max-width: 480px) {
  .landing-page .features-grid {
    grid-template-columns: 1fr;
  }
}

.landing-page .container {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  gap: 50px;
}

.landing-page .container-bottom {
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  gap: 50px;
}

.landing-page .image-section {
  flex: 1;
}

.landing-page .illustration {
  max-width: 100%;
  height: auto;
}

.landing-page .content-section {
  flex: 1;
}

.landing-page .tag {
  display: inline-block;
  background-color: #e6f3ff;
  color: #0066cc;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.landing-page .tag-benefits {
  display: inline-block;
  background-color: #3b89ff;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.landing-page h1 {
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.landing-page p {
  font-size: 20px;
  line-height: 1.6;
  color: #333;
}

.landing-page .highlight {
  color: #0066cc;
}

@media (max-width: 768px) {
  .landing-page .container {
    flex-direction: column;
  }
  
  .landing-page .image-section {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.landing-page .cta-container {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 30px;
}

.landing-page .email-input-container {
  display: flex;
  flex-direction: column;
}

.landing-page .email-label {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.landing-page .email-input {
  width: 300px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.landing-page .email-input::placeholder {
  color: #999;
}

@media (max-width: 768px) {
  .landing-page .cta-container {
    flex-direction: column;
    align-items: stretch;
  }
  
  .landing-page .email-input-container {
    margin-bottom: 20px;
  }
  
  .landing-page .email-input {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .landing-page .features-columns {
    flex-direction: column;
  }
  
  .landing-page .feature-column {
    width: 100%;
    justify-content: space-between;
  }
}

.landing-page .testimonials-section {
  background-color: #ffffff;
}

.landing-page .testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 10px;
}

.landing-page .testimonial-card {
  background: rgb(207, 207, 207);
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  scale: 0.90;
}

.landing-page .testimonial-card:hover {
  transform: translateY(-5px);
}

.landing-page .testimonial-header {
  display: flex;
  align-items: center;
}

.landing-page .testimonial-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.landing-page .testimonial-info h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.landing-page .testimonial-role,
.landing-page .testimonial-company {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.landing-page .testimonial-rating {
  color: #ffc107;
  font-size: 20px;
  margin-bottom: 5px;
}

.landing-page .testimonial-quote {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
  font-style: italic;
  margin: 0;
}

@media (max-width: 1024px) {
  .landing-page .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .landing-page .testimonials-grid {
    grid-template-columns: 1fr;
  }
}
.landing-page .features-grid-section .feature-selection {
  text-align: center;
  background-color: #e6f3ff;
}

.landing-page .features-grid-section .feature-selection .section-header {
  text-align: center;
  padding: 0% 0% 2% 0%;
  background-color: #e6f3ff;
}

.landing-page .features-grid-section .feature-selection h2 {
  font-size: 2.5em;
  margin: 15px 0;
  color: #333;
}

.landing-page .footer {
  background-color: #ffffff;
  padding: 60px 0 20px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.landing-page .footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  gap: 40px;
}

.landing-page .footer-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.landing-page .footer-logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.landing-page .footer-logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.landing-page .footer-section h3 {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.landing-page .footer-section a {
  color: #666;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.landing-page .footer-section a:hover {
  color: #0066ff;
}

.landing-page .footer-cta {
  background-color: #0066ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.landing-page .footer-bottom {
  max-width: 1200px;
  margin: 40px auto 0;
  padding: 20px;
  border-top: 1px solid #eee;
  text-align: center;
  color: #666;
  font-size: 14px;
}

@media (max-width: 768px) {
  .landing-page .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .landing-page .footer-section {
    align-items: center;
  }

  .landing-page .footer-logo {
    justify-content: center;
  }
}

.landing-page .email-input-container .textarea-input {
  resize: vertical;
  min-height: 80px;
  font-family: inherit;
  padding: 10px;
  line-height: 1.5;
}

.landing-page .email-input-container textarea::placeholder {
  color: #999;
}

.landing-page .input-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.landing-page .left-inputs {
  flex: 1;
}

.landing-page .right-inputs {
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.landing-page .right-inputs .textarea-input {
  height: 100%;
  resize: none;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .landing-page .input-section {
    flex-direction: column;
  }
  
  .landing-page .right-inputs .textarea-input {
    min-height: 120px;
  }
}

.landing-page .name-row {
  display: flex;
  gap: 15px;
  width: 300px;
}

.landing-page .name-input {
  flex: 1;
  width: 142.5px; /* (300px - 15px gap) / 2 */
}

.landing-page .name-input .email-input {
  width: 100%;
  box-sizing: border-box;
}

.landing-page .email-input {
  width: 300px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .landing-page .name-row {
    flex-direction: column;
    gap: 0;
  }
}
