.app {
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.social {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  margin-top: 100px;
}

label {
  display: block;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.social {
  text-decoration: underline;
}


.button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}


.loading-spinner {
  text-align: center;
  margin-top: 10px;
  color: #007bff;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
}